import classNames from 'classnames';

const SidebarHeader = (props) => {
  const { classes, sidebarCollapsed } = props;

  let imageURL = sidebarCollapsed ? 'logo-bare.svg' : 'SVG/banner-white.svg';
  imageURL = `${process.env.PUBLIC_URL}/static/img/${imageURL}`;

  return (
    <div className={` logo-container ${classes.logo}`}>
      <div className={classes.logoLink}>
        <div className={classes.logoImage}>
          <img
            src={imageURL}
            alt="logo"
            className={classNames({
              [classes.imgFull + ' big-logo ']: !sidebarCollapsed,
              [classes.imgSmall + ' small-logo ']: sidebarCollapsed,
            })}
          />
        </div>
      </div>
    </div>
  );
};

export default SidebarHeader;
